<template>
    <div id="app">
        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </div>
</template>

<script>
    export default {
        name: 'App',
    }
</script>

<style lang="less">
    html, body, div, input, button, textarea {
        font-family:-apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "PingFang SC",
        "Hiragino Sans GB",
        "Microsoft YaHei",
        "Helvetica Neue",
        Helvetica,
        Arial,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol";
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    html, body, #app {
        height:100%;
        overflow:scroll;
        color:#3F3F3F;
    }
    button {
        cursor:pointer;
    }
    a {
        text-decoration:none;
        color:#5C92E1;
    }
    #app {
        width:100%;
        > div:first-child {
            min-height:100%;
        }
        .content {
            width:1200px;
            margin:0 auto;
        }
    }
</style>
