<template>
  <div :class="['footer', {light: themes === 'light', transparent: themes === 'transparent'}]">
    <div class="content">
      <div>
        <div><a href="https://www.qilvapp.com/h5/user_agreement">用户协议</a></div>
        <div><a href="https://www.qilvapp.com/h5/privacy_agreement">隐私政策</a></div>
        <div><a href="/asc/content_standard">帮助中心</a></div>
      </div>
      <!-- todo www.iqilv.com用第一个备案 -->
<!--      <div>
          <div>Copyright ©2022 All Right Reserved</div>
          <div>广州爱奇旅信息科技有限公司</div>
          <div><a href="https://beian.miit.gov.cn/">粤ICP备16002833号</a></div>
      </div>-->
      <!-- todo www.qilvapp.com用第二个备案 -->
      <div>
        <div>Copyright ©2022 All Right Reserved</div>
        <div>爱奇创新科技（广州）有限公司</div>
        <div><a href="https://beian.miit.gov.cn/">粤ICP备2021092559号</a></div>
        <div>
          <!-- 公安备案号只属于qilvapp -->
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010502002403"
             style="display:inline-block;text-decoration:none;">
            <img src="@/assets/images/common/备案图标.png" style="float:left;margin-top: -5px"/>
            <p style="float:left;margin: 0px 0px 0px 5px;">
              粤公网安备 44010502002403号
            </p>
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "QilvFooter",
  props: ['themes']
}
</script>

<style scoped lang="less">
.footer {
  font-family: PingFangSC-Regular, serif;
  font-size: 14px;
  letter-spacing: 0.7px;
  line-height: 14px;
  font-weight: 300;
  color: white;
  height: 124px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, transparent 100%);

  .content {
    padding: 36px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      display: flex;
      padding: 6px 0;

      div {
        padding: 0 12px;

        & + div {
          border-left: 2px solid white;
        }
      }
    }

    a {
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // light 主题样式：
  &.light {
    color: #555555;
    background-color: #F7F8F9;
    background-image: none;

    .content > div div {
      border-color: rgba(85, 85, 85, 0.5);
    }

    a {
      color: #555555;
    }
  }

  // 透明主题样式：
  &.transparent {
    color: #555555;
    background-color: transparent;
    background-image: none;

    .content > div div {
      border-color: rgba(85, 85, 85, 0.5);
    }

    a {
      color: #555555;
    }
  }
}
</style>