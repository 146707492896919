<template>
    <button
        :class="['ql-button', {'primary': type === 'primary'}]"
        :style="{width: buttonWidth}"
        @click="$emit('click')"
        v-bind="$attrs">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: "QlButton",
        props: {
            type: {
                type: String,
                default: 'default'
            },
            width: [Number, String],
        },
        computed: {
            buttonWidth() {
                switch (Object.prototype.toString.call(this.width)) {
                    case "[object Number]":
                        return this.width > 0 ? this.width + 'px' : this.width
                    case "[object String]":
                        if (this.width.length > 0 && (this.width.substring(this.width.length - 1) === '%' || this.width.substring(this.width.length - 2) === 'px')) {
                            return this.width
                        } else {
                            return parseInt(this.width) + 'px'
                        }
                    default:
                        return null
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .ql-button {
        border:none;
        border-radius:8px;
        font-size:14px;
        font-weight:500;
        color:#555555;
        line-height:20px;
        background-color:#F8F8F8;
        box-sizing:border-box;
        padding:12px 32px;
        &.primary {
            color:white;
            background-color:#FE5038;
        }
        & + & {margin-left:16px;}
    }
</style>