<template>
    <label class="ql-radio" :class="{'is-disabled': isDisabled, 'is-checked': model === label}">
        <span class="radio_input">
            <span class="radio_inner"></span>
            <input
                ref="radio"
                class="radio_original"
                v-model="model"
                :value="label"
                type="radio"
                v-bind="$attrs"
                :disabled="isDisabled"
                @change="handleChange">
        </span>
        <span class="radio_label"><slot></slot></span>
    </label>
</template>

<script>
    import Emitter from "../../mixins/Emitter";

    export default {
        name: "QlRadio",
        componentName: "QlRadio",
        mixins: [Emitter],
        props: {
            value: {},
            label: {},
            disabled: Boolean,
        },
        data() {
            return {}
        },
        computed: {
            isDisabled() {
                return this.radioGroup != null
                    ? this.radioGroup.disabled || this.disabled
                    : this.disabled
            },
            radioGroup() {
                let parent = this.$parent
                while (parent) {
                    if (parent.$options.componentName !== 'QlRadioGroup') {
                        parent = parent.$parent
                    } else {
                        return parent
                    }
                }
                return null
            },
            model: {
                get() {
                    return this.radioGroup == null ? this.value : this.radioGroup.value
                },
                set(val) {
                    if (this.radioGroup != null) {
                        this.dispatch('QlRadioGroup', 'input', [val]);
                    } else {
                        this.$emit('input', val);
                    }
                    this.$refs.radio && (this.$refs.radio.checked = this.model === this.label);
                }
            }
        },
        methods: {
            handleChange() {
                this.$nextTick(() => {
                    this.$emit('change', this.model);
                    this.radioGroup != null && this.dispatch('QlRadioGroup', 'handleChange', this.model);
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .ql-radio {
        cursor:pointer;
        display:flex;
        min-width:98px;
        background-color:#F8F8F8;
        border-radius:8px;
        font-size:14px;
        line-height:20px;
        font-weight:500;
        padding:12px 20px;
        &.is-checked {
            color:#FE5038;
            background-color:rgba(254, 80, 56, 0.1);
            .radio_input .radio_inner {
                background-image:url("../../assets/images/icons/ic_radio_check.png");
            }
        }
        .radio_input {
            display:flex;
            .radio_inner {
                width:20px;
                height:20px;
                display:inline-block;
                background-image:url("../../assets/images/icons/ic_radio.png");
                background-size:100% 100%;
            }
            .radio_original {
                opacity:0;
                cursor:pointer;
            }
        }
        .radio_label {}
    }
</style>