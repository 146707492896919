<template>
    <div class="radio-group">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "QlRadioGroup",
        componentName: "QlRadioGroup",
        props: {
            value: {},
            disabled: Boolean,
        },
        created() {
            this.$on('handleChange', value => {
                this.$emit('change', value)
            })
        },
    }
</script>

<style scoped lang="less">
    .radio-group {
        display:flex;
        gap:0 16px;
    }
</style>