<template>
    <div class="video-slider">
        <slider animation="normal" width="100%" height="100%" :control-btn="false" :speed="500" :interval="8000" @change="onSliderChange">
            <slider-item>
                <div class="video-title">「打卡」诗和远方</div>
                <div class="video-player">
                    <video autoplay="autoplay" muted loop>
                        <source src="https://qilvalbum.oss-cn-shenzhen.aliyuncs.com/clients/resources/web_static/video/clock-in.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="masker-top"></div>
                <div class="masker-left"></div>
            </slider-item>
            <slider-item>
                <div class="video-title">「记录」美好足迹</div>
                <div class="video-player">
                    <video autoplay="autoplay" muted loop>
                        <source src="https://qilvalbum.oss-cn-shenzhen.aliyuncs.com/clients/resources/web_static/video/record.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="masker-top"></div>
                <div class="masker-left"></div>
            </slider-item>
            <slider-item>
                <div class="video-title">「连接」城市乡村</div>
                <div class="video-player">
                    <video autoplay="autoplay" muted loop>
                        <source src="https://qilvalbum.oss-cn-shenzhen.aliyuncs.com/clients/resources/web_static/video/connectivity.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="masker-top"></div>
                <div class="masker-left"></div>
            </slider-item>
            <slider-item>
                <div class="video-title">「探索」星辰大海</div>
                <div class="video-player">
                    <video autoplay="autoplay" muted loop>
                        <source src="https://qilvalbum.oss-cn-shenzhen.aliyuncs.com/clients/resources/web_static/video/discovery.mp4" type="video/mp4">
                    </video>
                </div>
                <div class="masker-top"></div>
                <div class="masker-left"></div>
            </slider-item>
        </slider>
    </div>
</template>

<script>
    export default {
        name: "VideoSlider",
        methods: {
            onSliderChange(index) {
                this.$el.querySelectorAll("video")[index].play()
            },
        },
        activated() {
            // 切换页面继续播放视频。
            this.$el.querySelectorAll('.slider-item').forEach(item => {
                if (item.style.display !== 'none') {
                    item.querySelector('video').play()
                }
            })
        },
    }
</script>

<style lang="less" scoped>
    .video-slider {
        width:100%;
        height:100%;
        position:fixed;
        z-index:-1;
        top:0;
        left:0;
        .slider-item {
            display:flex;
            justify-content:center;
        }
        .video-title {
            font-size:54px;
            font-weight:600;
            color:white;
            width:1200px;
            display:flex;
            flex-direction:column;
            justify-content:center;
            margin-bottom:160px;
        }
        .video-player {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:-3;
            video {
                width:100%;
                height:100%;
                object-fit:fill;
            }
        }
        .masker-top {
            width:100%;
            height:42%;
            z-index:-2;
            position:absolute;
            top:0;
            left:0;
            background:linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, transparent 100%);
        }
        .masker-left {
            width:77.5%;
            height:100%;
            z-index:-2;
            position:absolute;
            top:0;
            left:0;
            background:linear-gradient(to left, transparent 0%, rgba(0, 0, 0, .32) 100%);
        }
    }
</style>