<template>
    <div class="asc-header">
        <div class="content">
            <div class="logo" @click="$router.push('/').catch(err => err)"></div>
            <div class="logger-info" v-if="loginUser.loginState">
                <div class="avatar">
                    <img :src="loginUser.avatar" alt="">
                </div>
                <div class="username">{{ loginUser.username }}</div>
                <div class="separator"></div>
                <div class="logout" @click="logout">退出登录</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AscHeader",
        methods: {
            logout() {
                this.$store.commit('logout')
                this.$router.push('/asc/login').catch(err => err)
            }
        },
        computed: {
            loginUser() {
                return this.$store.state.loginUser
            }
        },
    }
</script>

<style scoped lang="less">
    .asc-header {
        width:100%;
        height:80px;
        background-color:white;
        color:#3F3F3F;
        .content {
            height:80px;
            display:flex;
            justify-content:space-between;
            align-items:center;
            .logo {
                width:294px;
                height:52px;
                background-image:url("../assets/images/acs_logo.png");
                background-size:100% 100%;
                cursor:pointer;
            }
            .logger-info {
                display:flex;
                align-items:center;
                font-size:14px;
                > div {
                    height:20px;
                }
                .avatar {
                    width:32px;
                    height:32px;
                    border-radius:100%;
                    overflow:hidden;
                    background-color:#F0F0F0;
                    margin-right:12px;
                    img {width:100%;height:100%;display:block;}
                }
                .separator {
                    width:2px;
                    height:16px;
                    margin:0 16px;
                    background-color:#F0F0F0;
                }
                .logout {
                    color:#848585;
                    cursor:pointer;
                }
            }
        }
    }
</style>