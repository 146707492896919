<template>
    <div :class="[type === 'textarea' ? 'qilv-textarea': 'qilv-input']">
        <template v-if="type !== 'textarea'">
            <div :class="['input-icon', iconClass]"></div>
            <input type="text" v-bind:value="value" v-bind="$attrs" @input="handleTextInput" @focus="onFocus" @keyup="onKeyup" @blur="onBlur">
        </template>
        <template v-else>
            <textarea v-bind:value="value" v-bind="$attrs" @input="handleTextareaInput"></textarea>
            <span class="input-counter">{{ inputLength }}/{{ letterLimit }}</span>
        </template>
    </div>
</template>

<script>
    export default {
        name: "QilvInput",
        model: {
            prop: 'value',
            event: 'input'
        },
        props: {
            value: [String, Number],
            type: String,
            limit: Number,
            iconClass: String,
        },
        data() {
            return {
                inputLength: 0,
                letterLimit: 120,
            }
        },
        methods: {
            handleTextareaInput(event) {
                let value = event.target.value
                if (value.length > this.letterLimit) {
                    value = value.slice(0, this.letterLimit)
                }
                this.inputLength = value.length
                this.$emit('input', value)
            },
            handleTextInput(event) {
                this.$emit('input', event.target.value)
            },
            onBlur() {
                this.$emit('blur')
            },
            onFocus() {
                this.$emit('focus')
            },
            onKeyup() {
                this.$emit('keyup')
            },
        },
    }
</script>

<style scoped lang="less">
    .qilv-input {
        position:relative;
        .input-icon {
            width:24px;
            height:24px;
            background-size:100% 100%;
            position:absolute;
            top:10px;
            left:16px;
            & + input {
                padding-left:48px;
            }
        }
        input {
            font-size:14px;
            line-height:20px;
            padding:11px 16px;
            background-color:#F8F8F8;
            border:1px solid #F8F8F8;
            border-radius:8px;
            width:100%;
            outline:none;
            &:focus {
                border-color:#FE5038;
            }
        }
        .icon-position {background-image:url("../../assets/images/icons/ic_position.png");}
    }

    .qilv-textarea {
        position:relative;
        textarea {
            background-color:#F8F8F8;
            border:1px solid #F8F8F8;
            border-radius:8px;
            resize:none;
            padding:16px;
            width:100%;
            min-height:120px;
            outline:none;
            &:focus {
                border-color:#FE5038;
            }
        }
        .input-counter {
            font-size:14px;
            color:#555555;
            font-weight:500;
            position:absolute;
            right:16px;
            bottom:16px;
        }
    }
</style>