import AscHeader from "./AscHeader"
import AscSidebar from "./AscSidebar"
import Button from './form/Button'
import Footer from "./Footer"
import FormItem from "./form/FormItem"
import Header from "./Header"
import Input from "./form/Input"
import MainContent from "./MainContent"
import Radio from "./form/Radio"
import RadioGroup from "./form/RadioGroup"
import VideoSlider from "./VideoSlider"

const components = [
    AscHeader,
    AscSidebar,
    Button,
    Footer,
    FormItem,
    Header,
    Input,
    MainContent,
    Radio,
    RadioGroup,
    VideoSlider,
]

export default {
    install: function (Vue) {
        components.forEach(component => {
            Vue.component(component.name, component)
        })
    }
}