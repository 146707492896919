import Vue from 'vue'
import App from './App.vue'
import router from './routes'
import store from './store'
import http from './http'
import components from './components'
import utils from './utils'
import EasySlider from 'vue-easy-slider'

Vue.use(components)
Vue.use(utils)
Vue.use(EasySlider)

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$redirect = function (path) {
    this.$router.push(path).catch(err => err)
}

window.vmroot = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
