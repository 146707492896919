<template>
    <div class="sidebar">
        <div class="publish-btn">
            <button role="menuitem" data-link="/asc/video_upload">
                <span>发布视频</span>
            </button>
        </div>
        <div class="sidebar-menu">
            <div class="sidebar-menu-item">
                <div :class="['menu-item', {active: $route.path === '/asc/video_list'}]" role="menuitem" data-link="/asc/video_list">
                    <div class="prepend ic-content"></div>
                    <div class="menu-text">
                        <span>内容管理</span>
                    </div>
                </div>
            </div>
            <div :class="['sidebar-menu-item', {opened:$route.path==='/asc/content_standard' || $route.path === '/asc/contact_us'}]">
                <div :class="['menu-item', {active: $route.path === '/asc/content_standard' || $route.path === '/asc/contact_us'}]" @click="onHelpClick">
                    <div class="prepend ic-help"></div>
                    <div class="menu-text">
                        <span>帮助中心</span>
                    </div>
                    <div class="append ic-dropdown"></div>
                </div>
                <div class="sub-menu-item">
                    <div :class="['menu-item', {active: $route.path === '/asc/content_standard'}]" role="menuitem" data-link="/asc/content_standard">
                        <div class="prepend"></div>
                        <div class="menu-text">
                            <span>内容规范</span>
                        </div>
                    </div>
                  <!-- todo www.iqilv.com去除联系我们 -->
                    <div :class="['menu-item', {active: $route.path === '/asc/contact_us'}]" role="menuitem" data-link="/asc/contact_us">
                        <div class="prepend"></div>
                        <div class="menu-text">
                            <span>联系我们</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AscSidebar",
        data() {
            return {
                menuItems: null
            }
        },
        methods: {
            onHelpClick($event) {
                $event.currentTarget.parentElement.classList.toggle('opened')
            },
            onMenuItemClick(event) {
                let link = event.currentTarget.dataset.link
                if (link != null) {
                    this.$router.push(link)
                }
            }
        },
        mounted() {
            this.$el.querySelectorAll('[role="menuitem"]').forEach(item => {
                item.addEventListener('click', this.onMenuItemClick)
            })
        },
        destroyed() {
            this.$el.querySelectorAll('[role="menuitem"]').forEach(item => {
                item.removeEventListener('click', this.onMenuItemClick)
            })
        }
    }
</script>

<style scoped lang="less">
    .sidebar {
        width:238px;
        background-color:white;
        border-radius:10px;
        .publish-btn {
            padding:20px 16px;
            button {
                width:100%;
                height:48px;
                color:white;
                font-size:16px;
                font-weight:500;
                background-color:#FE5038;
                border:1px solid #FE5038;
                border-radius:8px;
            }
        }
        .sidebar-menu {
            margin:0 16px;
            padding-top:24px;
            border-top:1px solid #F0F0F0;
            .sidebar-menu-item {
                .menu-item {
                    cursor:pointer;
                    display:flex;
                    padding:8px 16px;
                    &:hover {
                        border-radius:8px;
                        background-color:#F5F5F5;
                    }
                    .menu-text {
                        flex:1;
                        font-size:14px;
                        font-weight:500;
                        line-height:24px;
                        padding:0 12px;
                    }
                    .prepend, .append {
                        width:24px;
                        height:24px;
                        background-size:100% 100%;
                    }
                    .ic-help {background-image:url("../assets/images/icons/ic_help.png")}
                    .ic-content {background-image:url("../assets/images/icons/ic_content.png")}
                    .ic-dropdown {
                        transition:transform 0.3s;
                        transform:rotate(180deg);
                        background-image:url("../assets/images/icons/ic_PullDown.png");
                    }
                    &.active {
                        .menu-text {color:#FE5038;}
                        .ic-content {background-image:url("../assets/images/icons/ic_content_h.png")}
                        .ic-help {background-image:url("../assets/images/icons/ic_help_h.png")}
                        //.ic-dropdown {
                        //    background-image:url("../assets/images/icons/ic_PullDown_h.png")
                        //}
                    }
                }
                .sub-menu-item {
                    height:0;
                    overflow:hidden;
                }
                & + .sidebar-menu-item {margin-top:16px;}
                &.opened {
                    .menu-item {
                        .ic-dropdown {
                            transform:rotate(0deg);
                        }
                        //&.active .ic-dropdown {
                        //    transform:rotate(180deg);
                        //}
                    }
                    .sub-menu-item {
                        height:auto;
                        transition:height 0.3s;
                    }
                }
            }
        }
    }
</style>