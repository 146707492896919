<template>
    <div class="form-item">
        <div class="form-label">{{ label }}</div>
        <div class="form-control">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FormItem",
        props: ["label"],
    }
</script>

<style scoped lang="less">
    .form-item {
        margin-top:32px;
        .form-label {
            font-weight:500;
            line-height:22px;
            margin-bottom:8px;
        }
    }

</style>