import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const home = () => import('./views/Home')
const about = () => import('./views/ContentViews/About')
const content = () => import('./views/HomeContent')
const contact = () => import('./views/ContentViews/Contact')
const login = () => import('./views/ArtistServiceCenter/Login')
const videoList = () => import('./views/ArtistServiceCenter/VideoList')
const ascMain = () => import('./views/ArtistServiceCenter/AscMain')
const ascUploader = () => import('./views/ArtistServiceCenter/AscUploader')

const routes = [
    {
        path: '/',
        component: home
    },
    {
        path: '/content',
        component: content,
        redirect: '/content/contact',
        children: [
            {
                path: 'contact',
                meta: {title: '联系我们'},
                component: contact
            },
            {
                path: 'about',
                meta: {title: '关于我们'},
                component: about
            },
        ]
    },
    {
        path: '/asc/login',
        component: login
    },
    {
        path: '/asc',
        component: ascMain,
        redirect: '/asc/video_upload',
        children: [
            {
                path: 'video_upload',
                component: ascUploader
            },
            {
                path: 'video_list',
                name: 'videoList',
                component: videoList
            },
            {
                path: 'content_standard',
                component: () => import('./views/ArtistServiceCenter/CreatorAgreement')
            },
            {
                path: 'contact_us',
                component: contact
            }
        ]
    },
]

export default new VueRouter({
    mode: 'history',
    routes
})