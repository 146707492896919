const utils = {
    isEmpty: function (obj) {
        return obj === undefined || obj === null || obj === ""
    }
}
export default {
    install: function (Vue) {
        Object.keys(utils).forEach(key => {
            Vue.prototype[key] = utils[key]
        })
    }
}