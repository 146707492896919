import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        loginUser: {
            loginState: false,
            username: null,
            avatar: null,
            access_token: null,
        },
        contact: {
            email: 'feedback@iqib.com',
            report: 'jubao@iqib.com',
            bd: 'bd@iqib.com'
        }
    },
    mutations: {
        login(state, loginUser) {
            state.loginUser = loginUser
        },
        logout(state) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('access_token_expire')
            state.loginUser = Object.assign(state.loginUser, {
                loginState: false,
                username: null,
                avatar: null,
                access_token: null,
            })
        },
    }
})

export default store
