import axios from 'axios'

const http = axios.create()

http.interceptors.request.use(config => {
    let access_token = localStorage.getItem('access_token')
    if (access_token != null) {
        config.headers.authorization = access_token
    }
    return config
}, error => {
    return Promise.reject(error)
})

http.interceptors.response.use(response => response, error => {
    if (error.response !== undefined) {
        let {response: {status, data: {message}}} = error
        switch (status) {
            case 401:
            case 403:
                if (window.vmroot) {
                    localStorage.removeItem('access_token')
                    window.vmroot.$router.push('/asc/login').catch(err => err)
                }
                break;
            case 500:
                console.log(message)
                break;
        }
    }
    return Promise.reject(error)
})

export default http
