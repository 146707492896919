<template>
    <div :class="['header', {light: lightTheme}]">
        <div class="content">
            <div class="menu-item logo" data-link="/"></div>
            <div class="menu">
                <div :class="['menu-item', 'index', {active: $route.path === '/'}]" data-link="/">首页</div>
                <div class="menu-item creator" data-link="/asc/login">
                    <div></div>
                    <div>创作者服务</div>
                </div>
                <div :class="['menu-item', 'about', {active: /\/content/.test($route.path)}]">
                    <div>关于我们</div>
                    <div class="icon-dropdown"></div>
                    <div class="dropdown-menu">
                        <div class="menu-item dropdown-menu-item" data-link="/content/about">关于我们</div>
                      <!-- todo www.iqilv.com去除联系我们 -->
                      <div class="menu-item dropdown-menu-item" data-link="/content">联系我们</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "QilvHeader",
        props: ['themes'],
        methods: {
            onMenuItemClick(event) {
                let target = event.currentTarget
                if (target.classList.contains('about')) {
                    target.classList.toggle('down')
                }
                if (target.dataset.link != null) {
                    this.$router.push(
                        event.currentTarget.dataset.link
                    ).catch(err => err)
                }
            },
        },
        computed: {
            lightTheme() {
                return this.themes === 'light'
            }
        },
        mounted() {
            this.$el.querySelectorAll('.menu-item').forEach(item => {
                item.addEventListener('click', this.onMenuItemClick)
            })
        },
        destroyed() {
            this.$el.querySelectorAll('.menu-item').forEach(item => {
                item.removeEventListener('click', this.onMenuItemClick)
            })
        },
    }
</script>

<style scoped lang="less">
    .header {
        height:120px;
        font-size:16px;
        color:white;
        font-weight:500;
        .content {
            padding:28px 0;
            display:flex;
            justify-content:space-between;
            & > div {
                cursor:pointer;
            }
            .logo {
                width:160px;
                height:64px;
                background-image:url("../assets/images/home_logo.png");
                background-size:100% 100%;
            }
            .menu {
                width:404px;
                display:flex;
                justify-content:space-between;
                align-items:center;
                & > div {
                    height:22px;
                    display:inline-block;
                }
                .index {
                    width:36px;
                    text-align:center;
                    &.active::after {
                        content:" ";
                        display:block;
                        width:36px;
                        height:14px;
                        background:url("../assets/images/home_tap.png");
                        background-size:100% 100%;
                    }
                }
                .creator {
                    display:flex;
                    align-items:center;
                    div:first-child {
                        width:80px;
                        height:32px;
                        background:url("../assets/images/home_note.png");
                        background-size:100% 100%;
                    }
                }
                .about {
                    display:flex;
                    align-items:center;
                    padding:8px 0;
                    position:relative;
                    &.active::after {
                        content:" ";
                        display:block;
                        width:68px;
                        height:14px;
                        background:url("../assets/images/icons/ic_home_dropdown_tab2.png") center/100%;
                        position:absolute;
                        top:22px;
                        left:0;
                    }
                    &.down {
                        .icon-dropdown {transform:rotate(180deg)}
                        .dropdown-menu {height:100px;}
                    }
                    .icon-dropdown {
                        width:24px;
                        height:24px;
                        background-image:url("../assets/images/home_dropdown.png");
                        background-size:100% 100%;
                        transition:transform .2s;
                    }
                    .dropdown-menu {
                        width:100px;
                        height:0;
                        overflow:hidden;
                        border-radius:8px;
                        position:absolute;
                        top:35px;
                        right:0;
                        z-index:10;
                        background:white;
                        box-shadow:0 0 5px -2px rgba(0, 0, 0, .2);
                        transition:height .2s;
                        &-item {
                            font-size:16px;
                            padding:14px;
                            color:#555555;
                            &:hover {
                                color:#FE5038;
                            }
                        }
                    }
                }
            }
        }

        // light 主题样式：
        &.light {
            color:#3F3F3F;
            border-bottom:1px solid #F0F0F0;
            background-color:white;
            & .content {
                .logo {
                    background-image:url("../assets/images/home_logo_light.png");
                }
                .menu .about .icon-dropdown {
                    background-image:url("../assets/images/home_dropdown_light.png");
                }
            }
        }
    }
</style>