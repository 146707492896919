<template>
    <div class="main-content">
        <div class="content">
            <div class="title">
                {{ title }}
                <span></span>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "QilvMainContent",
        props: ['title']
    }
</script>

<style scoped lang="less">
    .content {
        padding-top:52px;
        .title {
            display:inline-block;
            font-size:40px;
            font-weight:600;
            color:#3F3F3F;
            line-height:56px;
            margin-bottom:20px;
            span {
                content:" ";
                display:block;
                width:inherit;
                height:4px;
                border-radius:2px;
                background-color:#F0F0F0;
            }
        }
    }
</style>